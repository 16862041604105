<template>
  <div class="w-14 h-full bg-white text-center sticky top-48" style="min-width: 54px; max-width: 54px; overflow: hidden;">
    <div class="pt-0">
      <div v-for="st in states" v-bind:key="st" @click="select(st)" :class="[st === $store.getters['sales/menu'] ? 'flex flex-col items-center justify-center h-9 text-white text-xl rounded border-1 border-blue-700 bg-blue-700 cursor-pointer m-2 mt-0' : 'flex flex-col items-center justify-center h-9 text-blue-700 text-xl rounded border-2 border-white hover:border-blue-600 cursor-pointer m-2 mt-0']">
        <i :class="[menus[st]]"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Side",
  data() {
    return {
      menus: {
        "customer": "fal fa-user",
        "catalog": "fal fa-cars",
        "proposal": "fal fa-handshake-alt",
        "delivery": "fal fa-truck-ramp",
        "documents": "fal fa-folder-open",
      },
      states: ["customer", "catalog", "proposal", "delivery", "documents"]
    }
  },
  methods: {
    select(menu) {
      return this.$store.commit('sales/setMenu', menu);
    }
  }
}
</script>

<style scoped>

</style>